import { Component, inject } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MessageZoneComponent, UpdateComponent } from "@solidev/data";
import {
  AuthService,
  LiveMessageComponent,
  RouteSupportService,
  SupportMessageZoneComponent,
  WsService,
} from "@vivalya/lvadg";

@Component({
  selector: "lvf-root",
  templateUrl: "./app.component.pug",
  styleUrls: ["./app.component.sass"],
  standalone: true,
  imports: [
    RouterModule,
    MessageZoneComponent,
    UpdateComponent,
    LiveMessageComponent,
    SupportMessageZoneComponent,
  ],
})
export class FrontAppComponent {
  private _todos = inject(RouteSupportService);
  private _auth = inject(AuthService);
  private _ws = inject(WsService);
}
