import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@vivalya/lvadg';
import { DataMessageService } from '@solidev/data';

@Injectable({
  providedIn: 'root',
})
export class IsAnonymousGuard  {
  constructor(
    private _auth: AuthService,
    private _msgs: DataMessageService,
    private router: Router
  ) {}

  canMatch(
    route: Route,
    state: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._auth.isAnonymous;
  }
}
