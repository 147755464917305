import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from '@solidev/data';
import { HeaderComponent } from '../../../parts/header/header.component';
import { FooterComponent } from '../../../parts/footer/footer.component';
import { RouterModule } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'lvf-authenticated-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    BreadcrumbComponent,
    HeaderComponent,
    FooterComponent,
  ],
  templateUrl: './authenticated-layout.component.pug',
  styleUrls: ['./authenticated-layout.component.sass'],
})
export class AuthenticatedLayoutComponent {
  public env = environment;
}
