import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'lvf-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer.component.pug',
  styleUrls: ['./footer.component.sass'],
})
export class FooterComponent {
  public env = environment;
  public version = '0.0.0';
  public build = '01234';
}
