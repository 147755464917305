import { Component } from '@angular/core';
import { FRONT_ROUTES } from '../../../routing/main';
import { RouterLinkWithHref } from '@angular/router';

@Component({
  selector: 'lvf-home-anonymous',
  standalone: true,
  imports: [RouterLinkWithHref],
  templateUrl: './home-anonymous.component.pug',
  styleUrls: ['./home-anonymous.component.sass'],
})
export class HomeAnonymousComponent {
  public routes = FRONT_ROUTES;
}
