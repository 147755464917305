
<div class="full d-none d-md-block">
  <header class="navbar navbar-expand-sm navbar-dark bg-primary">
    <div class="container-fluid">
      <div class="navbar-brand my-0 py-0 position-relative"><img class="m-0 p-0" src="/assets/favicons/logo-header.png" height="32px">
        <div class="position-absolute text-danger top-0 mt-1 fw-bold text-center w-100 text-uppercase" *ngIf="env.mode!=='prod'">{{env.mode.substring(0,3)}}</div>
      </div>
      <div class="navbar-brand d-none d-xxl-block">#lavieadugout</div>
      <button class="navbar-toggler" type="button"><span class="navbar-toggler-icon"></span></button>
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav me-auto">
          <ng-container *ngFor="let m of menu$|async">
            <ng-container *ngIf="display(m) &amp;&amp; (!m.children || m.children.length == 0)">
              <li class="nav-item"><a class="nav-link text-uppercase" [routerLink]="m.route" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: m.exact}"><i class="bi me-2" [ngClass]="m.icon"></i>{{m.title}}</a></li>
            </ng-container>
            <ng-container *ngIf="display(m) &amp;&amp; (m.children &amp;&amp; m.children.length)">
              <li class="nav-item" ngbDropdown><a class="nav-link text-uppercase" ngbDropdownToggle role="button"><i class="bi" [ngClass]="m.icon"></i>&nbsp;{{m.title}}</a>
                <ul ngbDropdownMenu>
                  <button ngbDropdownItem [routerLink]="m.route"><i class="bi me-2" [ngClass]="m.icon"></i>{{m.altTitle||m.title}}</button>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <ng-container *ngFor="let s of m.children"><a ngbDropdownItem [routerLink]="s.route" *ngIf="display(s)"><i class="bi me-2" [ngClass]="s.icon"></i>{{s.title}}</a></ng-container>
                </ul>
              </li>
            </ng-container>
          </ng-container>
        </ul>
        <ul class="navbar-nav navbar-text text-white"><a class="px-4" [routerLink]="ROUTES.help.route('/front')" title="Documentation"><i class="bi bi-question-circle" aria-hidden="true"></i></a>
          <lvadg-print-current-icon class="me-4" [print_detail_route]="print_detail_route" [print_list_route]="print_list_route"></lvadg-print-current-icon>
          <!-- User zone--><i class="me-2" [ngClass]="RI.user"></i><span class="d-none d-lg-inline" role="button" [routerLink]="ROUTES.account.route()">{{auth.currentUser?.last_name}} {{auth.currentUser?.first_name}}</span><a class="ms-2" [ngClass]="RI.logout" [routerLink]="ROUTES.logout.route()"></a>
        </ul>
      </div>
    </div>
  </header>
</div>
<div class="mobile d-block d-md-none">
  <header class="navbar navbar-expand-lg navbar-dark bg-primary">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button"><span class="navbar-toggler-icon" (click)="toggleSideMenu()"></span></button>
      <div class="navbar-brand">#lavieadugout</div>
      <div class="navbar-brand my-0 py-0 position-relative"><img class="m-0 p-0" src="/assets/favicons/logo-header.png" height="32px">
        <div class="position-absolute text-danger top-0 mt-1 fw-bold text-center w-100 text-uppercase" *ngIf="env.mode!=='prod'">{{env.mode.substring(0,3)}}</div>
      </div>
    </div>
  </header>
</div>
<ng-template #sidemenu let-offcanvas>
  <div class="offcanvas-header"></div>
  <div class="offcanvas-body">
    <ul class="list-group">
      <ng-container *ngFor="let m of menu$|async">
        <ng-container *ngIf="!m.children || m.children.length == 0">
          <li class="list-group-item list-group-item-action"><a class="nav-link text-uppercase" [routerLink]="m.route" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: m.exact}" (click)="closeSideMenu()">{{m.title}}</a></li>
        </ng-container>
        <ng-container *ngIf="m.children &amp;&amp; m.children.length">
          <li class="list-group-item list-group-item-action"><a class="nav-link text-uppercase" [routerLink]="m.route" (click)="closeSideMenu()">{{m.title}}</a></li>
          <ng-container *ngFor="let s of m.children">
            <li class="list-group-item list-group-item-action"><a class="nav-link ms-3" [routerLink]="s.route" (click)="closeSideMenu()">{{s.title}}</a></li>
          </ng-container>
        </ng-container>
      </ng-container>
    </ul>
  </div>
</ng-template>