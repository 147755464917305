import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@vivalya/lvadg';
import { DataMessageService } from '@solidev/data';

@Injectable({
  providedIn: 'root',
})
export class IsAuthGuard
  
{
  constructor(
    private _auth: AuthService,
    private _msgs: DataMessageService,
    private router: Router
  ) {}

  canMatch(
    route: Route,
    state: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return !this._auth.isAnonymous;
  }

  canLoad(
    route: Route,
    state: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this._auth.isAnonymous) {
      this._msgs.warning('Merci de vous identifier - canload');
    }
    return this._auth.isAnonymous ? this.router.parseUrl('/') : true;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this._auth.isAnonymous) {
      this._msgs.warning('Merci de vous identifier - activate');
    }
    return this._auth.isAnonymous ? this.router.parseUrl('/') : true;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this._auth.isAnonymous) {
      this._msgs.warning('Merci de vous identifier - achild');
    }
    return this._auth.isAnonymous ? this.router.parseUrl('/') : true;
  }
}
