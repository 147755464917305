import { BaseRouteParams, RData, RoutesDefinition } from '@solidev/data';
import { HomeAnonymousComponent } from '../pages/home/home-anonymous/home-anonymous.component';
import {
  AuthService,
  DocpageResolver,
  DocsDisplayViewComponent,
  GenericErrorViewComponent,
  LoginViewComponent,
  LogoutViewComponent,
  META_GROUPS,
  PwresetViewComponent,
  ReactivateViewComponent,
  RI,
  RS,
  SPECIAL_GROUPS,
} from '@vivalya/lvadg';
import { AuthenticatedLayoutComponent } from '../pages/home/authenticated-layout/authenticated-layout.component';
import { FRONT_ROUTES } from './main';
import { IsAnonymousGuard } from './guards/is-anonymous-guard.service';
import { IsAuthGuard } from './guards/is-auth-guard.service';
import { IsInGroupGuard } from './guards/is-in-group.guard';
import { UrlSegment } from '@angular/router';
import { inject } from '@angular/core';
import { filter } from 'rxjs';

export const FRONT_ROUTES_DEF: RoutesDefinition = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeAnonymousComponent,
    data: RData<BaseRouteParams>({
      title: 'Accueil',
      route: FRONT_ROUTES.home,
    }),
    canMatch: [IsAnonymousGuard],
  },
  {
    path: RS.login,
    component: LoginViewComponent,
    data: RData<BaseRouteParams>({
      title: 'Login',
      route: FRONT_ROUTES.login,
    }),
  },
  {
    path: RS.logout,
    component: LogoutViewComponent,
    data: RData<BaseRouteParams>({
      title: 'Logout',
      route: FRONT_ROUTES.login,
    }),
  },
  {
    path: RS.pwreset,
    component: PwresetViewComponent,
    data: RData<BaseRouteParams>({
      title: 'Réinitialisation mot de passe',
      route: FRONT_ROUTES.pwreset,
    }),
  },
  {
    path: RS.reactivate,
    component: ReactivateViewComponent,
    data: RData<BaseRouteParams>({
      title: 'Réactiver mon compte',
      route: FRONT_ROUTES.reactivate,
    }),
  },
  {
    path: '',
    component: AuthenticatedLayoutComponent,
    data: RData<BaseRouteParams>({
      title: 'Accueil',
      icon: RI.home,
      route: FRONT_ROUTES.home,
    }),
    canMatch: [IsAuthGuard],
    canActivateChild: [IsAuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () => import('../pages/home/home/home.component').then((m) => m.HomeComponent),
        data: RData<BaseRouteParams>({
          skip: true,
          route: FRONT_ROUTES.home,
        }),
      },
      {
        path: RS.meteo,
        loadComponent: () =>
          import('../pages/meteo/home/meteo-home-view.component').then((m) => m.MeteoHomeViewComponent),
        data: RData<BaseRouteParams>({
          title: 'Meteo',
          route: FRONT_ROUTES.meteo,
        }),
      },
      {
        path: RS.documents,
        loadChildren: () => import('../pages/documents/documents.routes').then((m) => m.FRONT_DOCUMENTS_ROUTES_DEF),
        canMatch: [IsInGroupGuard.forGroups(SPECIAL_GROUPS.superuser, SPECIAL_GROUPS.staff, META_GROUPS.documents)],
        data: RData<BaseRouteParams>({
          title: 'Documents',
          route: FRONT_ROUTES.documents,
        }),
      },
      {
        path: RS.catalog,
        loadChildren: () => import('../pages/catalog/catalog.routes').then((m) => m.FRONT_CATALOG_ROUTES_DEF),
        canMatch: [IsInGroupGuard.forGroups(SPECIAL_GROUPS.superuser, SPECIAL_GROUPS.staff, META_GROUPS.catalog)],
        data: RData<BaseRouteParams>({
          title: 'Catalogue',
          icon: RI.catalog,
          route: FRONT_ROUTES.catalog,
        }),
        resolve: {
          user: () => {
            return inject(AuthService).currentUser$.pipe(filter((u) => !!u));
          },
        },
      },
      {
        path: RS.news,
        loadChildren: () => import('../pages/news/news.routes').then((m) => m.FRONT_PRINTS_ROUTES_DEF),
        canMatch: [IsInGroupGuard.forGroups(SPECIAL_GROUPS.superuser, SPECIAL_GROUPS.staff, META_GROUPS.news)],
        data: RData<BaseRouteParams>({
          title: 'Actualités',
          route: FRONT_ROUTES.actualites,
        }),
      },
      {
        path: RS.orders,
        loadChildren: () => import('../pages/orders/orders.routes').then((m) => m.FRONT_ORDER_ROUTES_DEF),
        canMatch: [IsInGroupGuard.forGroups(SPECIAL_GROUPS.superuser, SPECIAL_GROUPS.staff, META_GROUPS.orders)],
        data: RData<BaseRouteParams>({
          title: 'Commandes',
          icon: RI.order,
          route: FRONT_ROUTES.orders,
        }),
      },
      {
        path: RS.pilote,
        loadChildren: () => import('../pages/pilote/pilote.routes').then((m) => m.FRONT_PILOTE_ROUTES_DEF),
        canMatch: [IsInGroupGuard.forGroups(SPECIAL_GROUPS.superuser, SPECIAL_GROUPS.staff, META_GROUPS.pilote)],
        data: RData<BaseRouteParams>({
          title: 'Pilotage tarifaire',
          icon: RI.pilote,
          route: FRONT_ROUTES.pilote,
        }),
      },
      {
        path: RS.rnmtarifs,
        loadChildren: () => import('../pages/rnm/rnm.routes').then((m) => m.FRONT_RNM_ROUTES_DEF),
        canMatch: [IsInGroupGuard.forGroups(SPECIAL_GROUPS.superuser, SPECIAL_GROUPS.staff, META_GROUPS.rnm)],
        data: RData<BaseRouteParams>({
          title: 'RNM',
          icon: RI.rnm,
          route: FRONT_ROUTES.tarifs_rnm,
        }),
      },
      {
        path: RS.tarifs,
        loadChildren: () => import('../pages/tarifs/tarifs.routes').then((m) => m.FRONT_TARIFS_ROUTES_DEF),
        canMatch: [IsInGroupGuard.forGroups(SPECIAL_GROUPS.superuser, SPECIAL_GROUPS.staff, META_GROUPS.tarifs)],
        data: RData<BaseRouteParams>({
          title: 'Consultation tarifs',
          icon: RI.tarifs,
          route: FRONT_ROUTES.tarifs,
        }),
      },
      {
        path: RS.account,
        loadChildren: () => import('../pages/account/account.routes').then((m) => m.FRONT_ACCOUNT_ROUTES_DEF),
        data: RData<BaseRouteParams>({
          title: 'Mon compte',
          icon: RI.user,
          route: FRONT_ROUTES.account,
        }),
      },
      {
        path: RS.prints,
        loadChildren: () => import('../pages/prints/prints.routes').then((m) => m.FRONT_PRINTS_ROUTES_DEF),
        canMatch: [IsInGroupGuard.forGroups(SPECIAL_GROUPS.superuser, SPECIAL_GROUPS.staff, SPECIAL_GROUPS.all)],
        data: RData<BaseRouteParams>({
          title: 'Impressions',
          icon: RI.print,
          route: FRONT_ROUTES.prints,
        }),
      },
      {
        path: RS.fl,
        loadChildren: () => import('../pages/fl/fl.routes').then((m) => m.FRONT_FL_ROUTES_DEF),
        canMatch: [IsInGroupGuard.forGroups(SPECIAL_GROUPS.superuser, SPECIAL_GROUPS.staff, META_GROUPS.products)],
        data: RData<BaseRouteParams>({
          title: 'Fruits et légumes',
          icon: RI.fl,
          route: FRONT_ROUTES.fl,
        }),
      },
      {
        path: RS.sea,
        loadChildren: () => import('../pages/sea/sea.routes').then((m) => m.FRONT_SEA_ROUTES_DEF),
        canMatch: [IsInGroupGuard.forGroups(SPECIAL_GROUPS.superuser, SPECIAL_GROUPS.staff, META_GROUPS.products)],
        data: RData<BaseRouteParams>({
          title: 'Marée',
          icon: RI.sea,
          route: FRONT_ROUTES.sea,
        }),
      },
      {
        path: RS.storages,
        loadChildren: () => import('../pages/storages/storages.routes').then((m) => m.FRONT_STORAGES_ROUTES_DEF),
        canMatch: [IsInGroupGuard.forGroups(SPECIAL_GROUPS.superuser, SPECIAL_GROUPS.staff, META_GROUPS.storages)],
        data: RData<BaseRouteParams>({
          title: 'Dépôts',
          icon: RI.storage,
          route: FRONT_ROUTES.storages,
        }),
      },
      {
        path: RS.restos,
        loadChildren: () => import('../pages/restos/restos.routes').then((m) => m.FRONT_RESTOS_ROUTES_DEF),
        canMatch: [IsInGroupGuard.forGroups(SPECIAL_GROUPS.superuser, SPECIAL_GROUPS.staff, META_GROUPS.restos)],
        data: RData<BaseRouteParams>({
          title: 'Restaurants',
          icon: RI.resto,
          route: FRONT_ROUTES.restos,
        }),
      },
      {
        path: RS.providers,
        loadChildren: () => import('../pages/providers/providers.routes').then((m) => m.FRONT_PROVIDERS_ROUTES_DEF),
        canMatch: [IsInGroupGuard.forGroups(SPECIAL_GROUPS.superuser, SPECIAL_GROUPS.staff, META_GROUPS.providers)],
        data: RData<BaseRouteParams>({
          title: 'Fournisseurs',
          icon: RI.provider,
          route: FRONT_ROUTES.providers,
        }),
      },
      {
        path: RS.clients,
        loadChildren: () => import('../pages/clients/clients.routes').then((m) => m.FRONT_CLIENTS_ROUTES_DEF),
        canMatch: [IsInGroupGuard.forGroups(SPECIAL_GROUPS.superuser, SPECIAL_GROUPS.staff, META_GROUPS.clients)],
        data: RData<BaseRouteParams>({
          title: 'Clients',
          icon: RI.client,
          route: FRONT_ROUTES.clients,
        }),
      },

      {
        matcher: (url: UrlSegment[]) => {
          return url.length >= 1 && url[0].path === 'docs' ? { consumed: url } : null;
        },
        component: DocsDisplayViewComponent,
        data: RData<BaseRouteParams>({
          title: 'Documentation',
          route: FRONT_ROUTES.noop,
        }),
        resolve: { docpage: DocpageResolver },
      },
    ],
  },
  {
    path: 'error',
    data: RData<BaseRouteParams>({
      title: 'Erreur',
      route: FRONT_ROUTES.noop,
    }),
    component: GenericErrorViewComponent,
  },
  {
    path: '**',
    data: RData<BaseRouteParams>({
      title: 'Erreur',
      route: FRONT_ROUTES.noop,
    }),
    component: GenericErrorViewComponent,
  },
];
