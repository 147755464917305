import { FRONT_ROUTES } from "./main";
import { ADMIN_GROUPS, META_GROUPS, RI, SPECIAL_GROUPS } from "@vivalya/lvadg";

export interface ISubMenuItem {
  title: string;
  icon: string;
  route: (string | number)[];
  status: "todo" | "dev" | "beta" | "prod";
  beta?: (META_GROUPS | SPECIAL_GROUPS | ADMIN_GROUPS)[];
}

export interface IMenuItem {
  title: string;
  icon: string;
  altTitle?: string;
  route: (string | number)[];
  children?: ISubMenuItem[];
  groups: (META_GROUPS | SPECIAL_GROUPS)[];
  beta?: (META_GROUPS | SPECIAL_GROUPS | ADMIN_GROUPS)[];
  exact: boolean;
  status: "todo" | "dev" | "beta" | "prod";
}

export const FRONT_MENU: IMenuItem[] = [
  {
    title: "Accueil",
    icon: RI.home,
    route: FRONT_ROUTES.home.route(),
    children: [],
    groups: [SPECIAL_GROUPS.all],
    exact: true,
    status: "prod",
  },
  {
    title: "Docs",
    icon: RI.document,
    altTitle: "Accueil",
    route: FRONT_ROUTES.documents.route(),
    groups: [SPECIAL_GROUPS.superuser, META_GROUPS.documents],
    beta: [ADMIN_GROUPS.beta_documents],
    exact: false,
    status: "beta",
  },
  {
    title: "F&L",
    icon: RI.fl,
    altTitle: "Accueil",
    route: FRONT_ROUTES.fl.route(),
    status: "prod",
    children: [
      {
        title: "National",
        route: FRONT_ROUTES.fl_global.route(),
        icon: "bi-map",
        status: "prod",
      },
      {
        title: "Local",
        route: FRONT_ROUTES.fl_local.route(),
        icon: "bi-geo",
        status: "prod",
      },
    ],
    groups: [META_GROUPS.products, SPECIAL_GROUPS.superuser],
    exact: false,
  },
  {
    title: "Marée",
    altTitle: "Accueil",
    icon: "bi-water",
    route: FRONT_ROUTES.sea.route(),
    status: "prod",
    children: [
      {
        title: "Producteurs",
        route: FRONT_ROUTES.sea_producers.route(),
        icon: "bi-water",
        status: "prod",
      },
      {
        title: "Produits",
        route: FRONT_ROUTES.sea_products.route(),
        icon: "bi-water",
        status: "prod",
      },
    ],
    groups: [META_GROUPS.products, SPECIAL_GROUPS.superuser],
    exact: false,
  },
  {
    title: "Dépôts",
    altTitle: "Dépôts",
    icon: RI.storage,
    route: FRONT_ROUTES.storages.route(),
    children: [],
    groups: [SPECIAL_GROUPS.superuser, META_GROUPS.storages],
    exact: false,
    status: "prod",
  },
  {
    title: "Catalogue",
    altTitle: "Catalogue",
    icon: RI.catalog,
    route: FRONT_ROUTES.catalog.route(),
    children: [],
    groups: [
      SPECIAL_GROUPS.superuser,
      META_GROUPS.storages,
      META_GROUPS.catalog,
    ],
    exact: false,
    status: "prod",
  },
  {
    title: "Tarifs",
    icon: RI.tarifs,
    altTitle: "Consultation tarifs",
    route: FRONT_ROUTES.tarifs.route(),
    children: [],
    groups: [
      META_GROUPS.tarifs,
      SPECIAL_GROUPS.staff,
      SPECIAL_GROUPS.superuser,
    ],
    exact: false,
    status: "prod",
  },
  {
    title: "Pilote",
    icon: RI.pilote,
    altTitle: "Pilotage tarifaire",
    route: FRONT_ROUTES.pilote.route(),
    children: [],
    groups: [
      META_GROUPS.pilote,
      SPECIAL_GROUPS.staff,
      SPECIAL_GROUPS.superuser,
    ],
    exact: false,
    status: "prod",
  },
  {
    title: "RNM",
    icon: RI.rnm,
    altTitle: "Consultation RNM",
    route: FRONT_ROUTES.tarifs_rnm.route(),
    children: [],
    groups: [META_GROUPS.rnm, SPECIAL_GROUPS.superuser],
    exact: false,
    status: "dev",
  },
  {
    title: "Clients",
    icon: RI.client,
    altTitle: "Clients",
    route: FRONT_ROUTES.clients.route(),
    children: [],
    groups: [META_GROUPS.clients, SPECIAL_GROUPS.superuser],
    exact: false,
    status: "dev",
  },
  {
    title: "Restaurants",
    icon: RI.resto,
    altTitle: "Restaurants",
    route: FRONT_ROUTES.restos.route(),
    children: [],
    groups: [META_GROUPS.restos, SPECIAL_GROUPS.superuser],
    exact: false,
    status: "dev",
  },
  {
    title: "Fournisseurs",
    icon: RI.provider,
    altTitle: "Fournisseurs",
    route: FRONT_ROUTES.providers.route(),
    children: [
      {
        title: "Fournisseurs",
        route: FRONT_ROUTES.providers_providers.route(),
        beta: [ADMIN_GROUPS.providers_editor],
        icon: RI.provider,
        status: "beta",
      },
      {
        title: "Documents",
        route: FRONT_ROUTES.providers_documents.route(),
        icon: RI.document,
        beta: [ADMIN_GROUPS.providers_editor],
        status: "beta",
      },
    ],
    groups: [META_GROUPS.providers, SPECIAL_GROUPS.superuser],
    beta: [ADMIN_GROUPS.providers_editor],
    exact: false,
    status: "beta",
  },
  {
    title: "Commandes",
    altTitle: "Gestion des commandes",
    icon: RI.order,
    route: FRONT_ROUTES.orders.route(),
    children: [],
    groups: [META_GROUPS.orders, SPECIAL_GROUPS.superuser],
    exact: false,
    status: "beta",
  },
  {
    title: "Vivalya",
    altTitle: "Vivalya",
    icon: RI.vivalya,
    route: FRONT_ROUTES.noop.route(),
    children: [],
    groups: [SPECIAL_GROUPS.superuser],
    exact: false,
    status: "todo",
  },
  {
    title: "Météo",
    altTitle: "Météo",
    icon: RI.meteo,
    route: FRONT_ROUTES.meteo.route(),
    children: [],
    groups: [SPECIAL_GROUPS.all],
    exact: false,
    status: "prod",
  },
  {
    title: "Actualités",
    altTitle: "Actualités",
    icon: RI.news,
    route: FRONT_ROUTES.actualites.route(),
    children: [],
    groups: [META_GROUPS.news, SPECIAL_GROUPS.superuser],
    exact: false,
    status: "beta",
  },
];
