import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FRONT_MENU, IMenuItem, ISubMenuItem } from "../../routing/menu";
import { FRONT_ROUTES } from "../../routing/main";
import {
  AuthService,
  Print,
  PrintCurrentIconComponent,
  RI,
  SPECIAL_GROUPS,
} from "@vivalya/lvadg";
import { RouterModule } from "@angular/router";
import {
  NgbDropdownModule,
  NgbOffcanvas,
  NgbOffcanvasRef,
} from "@ng-bootstrap/ng-bootstrap";
import { environment } from "../../../environments/environment";
import { map, Observable } from "rxjs";
import { Link } from "@solidev/data";

@Component({
  selector: "lvf-header",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NgbDropdownModule,
    PrintCurrentIconComponent,
  ],
  templateUrl: "./header.component.pug",
  styleUrls: ["./header.component.sass"],
})
export class HeaderComponent implements OnInit {
  public menu$!: Observable<IMenuItem[]>;
  public ROUTES = FRONT_ROUTES;
  public RI = RI;
  public env = environment;
  @ViewChild("sidemenu", { static: true })
  public ofct!: TemplateRef<HTMLDivElement>;
  public smenu?: NgbOffcanvasRef;

  public print_detail_route = new Link<Print>(this.ROUTES.prints_print, {});
  public print_list_route = new Link<never>(this.ROUTES.prints, {});

  constructor(
    public auth: AuthService,
    private _ofc: NgbOffcanvas,
  ) {}

  ngOnInit(): void {
    this.menu$ = this.auth.currentUser$.pipe(
      map((u) => {
        return FRONT_MENU.filter((i) => {
          for (const g of i.groups) {
            if (g === SPECIAL_GROUPS.all) {
              return true;
            }
            if (g === SPECIAL_GROUPS.superuser && u?.is_superuser) {
              return true;
            }
            if (g === SPECIAL_GROUPS.staff && u?.is_staff) {
              return true;
            }
            if (
              u?.current_groups_slugs &&
              u.current_groups_slugs.indexOf(`${g}`) !== -1
            ) {
              return true;
            }
          }
          return false;
        });
      }),
    );
  }

  toggleSideMenu() {
    if (!this.smenu) {
      this.smenu = this._ofc.open(this.ofct, { position: "start" });
      this.smenu.result.then(
        () => {
          delete this.smenu;
        },
        () => {
          delete this.smenu;
        },
      );
    } else {
      this.smenu.close();
      delete this.smenu;
    }
  }

  closeSideMenu() {
    this.smenu?.close();
    delete this.smenu;
  }

  public display(i: IMenuItem | ISubMenuItem): boolean {
    if (i.status === "prod") {
      return true;
    }
    if (i.status === "beta") {
      for (const g of i.beta || []) {
        if (
          this.auth.currentUser?.current_groups_slugs?.indexOf(`${g}`) !== -1
        ) {
          return true;
        }
      }
      return localStorage.getItem("dev") === "true";
    }
    return localStorage.getItem("dev") === "true";
  }
}
